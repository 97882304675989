<template>
  <div class="card">
    <h3 class="card-title">{{ notificacion.titulo }}</h3>
      <hr/>
    <div class="d-flex" v-if="notificacion.archivo_url">
      <embed
          :src="notificacion.archivo_url"
          style="width: 100%; height: 29.7cm"
          frameborder="0"
      />
    </div>
    <div style="padding: 0 5px" v-html="notificacion.contenido" v-else></div>
    <div class="d-flex space-between mt-1" v-if="!notificacion.firmado">
      <button class="btn btn-primary" @click="marcarComoLeido">
        Marcar como leído
      </button>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2";
import {NotificacionesServices} from "../../notificaciones/services/NotificacionesServices";

export default {
  name: "VerMiNotificacion",
  data() {
    return {
      loading: false,
      notificacion: null,
    };
  },
  mounted() {
    const notificacionId = this.$route.params.id;
    this.loading = true;
    NotificacionesServices.api
        .findByUuid(notificacionId)
        .then((response) => {
          this.notificacion = response.notificacion;
        })
        .catch((error) => {
          Swal.fire("Error", `${error}`, "error");
        })
        .finally(() => (this.loading = false));
  },
  methods: {
    async marcarComoLeido() {
      try{
        await NotificacionesServices.api.marcarComoLeido(this.notificacion.id)
        await Swal.fire("Éxito", `La notificación se marcó como leída`, "success");
        window.location.href = '/mis-notificaciones'
      }catch (error) {
        Swal.fire("Error", `${error}`, "error");
      }
    },
  },
};
</script>
